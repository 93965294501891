<articles>
    <article>
        <h2>ספריית המאמרים שלנו</h2>
        <p>
            פה תמצאו חומרים שונים שקשורים לתהליך. המבנה הוא דמוי וויקי, ולכן ניתן לראות אותו כמפת-מוח, בה הדברים,
            המושגים, המיני-מאמרים, קשורים האחד לחבריו, ורק יחדיו מייצרים תמונה מלאה...
            <br>
            התכנים מעודכנים באופן שוטף. נוספים חומרים, מאמרים עוברים עריכה. הכל חי. חלק מהחומרים יהיה
            מובן ושימושי לכולם, חלק יתאים יותר
            למי שכבר יותר מצוי בסוד העניינים וחלק לוותיקים ומנוסים יותר.
            <br>
            קריאה מהנה!
        </p>
        <p>
            <b>
                שימו לב:
            </b>
            סרטוני וידאו אפשר למצוא ביוטיוב:
            <a href="https://www.youtube.com/@Lojong-studio">ערוץ היוטיוב שלנו</a>
            <br>
            ואת
            <b>
            הבלוג
            </b>
            אפשר למצוא
            <a href="/blog">
                פה, בבלוג!
            </a>
        </p>
        <div class="count">
            <template if={state.searchResults}>
                מציג
                {state.searchResults?.length}
                מאמרים מתוך
                {state.entries?.length}
            </template>
            <template if={!state.searchResults}>
                מציג
                {state.entries?.length}
                מאמרים,
                מתוכם
                {state.entries?.filter(ai => ai.finished === 'no').length}
                לא גמורים
            </template>

        </div>
        <label>
            <input class="search-field" type="text" onkeyup={updateSearch} placeholder="חיפוש" aria-label="search" }>
            <span role="button" class="clear" onclick={clearSearch}>X</span>
        </label>
        &nbsp;
        <div class="control">
            <div class="order">
                <span><label><input type="radio" name="sort" ref="sort" value="ascending"
                                    checked>חדשים קודם</label></span>
                <span><label><input type="radio" name="sort" ref="sort" value="descending">ישנים קודם</label></span>
            </div>
            <label><input type="checkbox" name="hideUnfinished" ref="hideUnfinished" checked>הסתר מאמרים לא
                גמורים</label>

        </div>

        <ol class="articles-list">
            <li each={ ai in (state.searchResults || state.entries) }
                class="{ `article-thumbnail + ${ai.finished ==='no'? 'unfinished': 'ready'}` }">
                <a href={"/lib/"+ai.name}>
                    <img src={ai.image || defaultImage} alt={ai.name + ' image'}>
                </a>
                <a href={"/lib/"+ai.name}>{ai.title || ai.name} </a>
                <div class="synopsis">{ai.abstract || ai.synopsis || ''}</div>
                <div if={ai.finished ==='no'}>[מאמר עדיין בעבודה]</div>
                <p class="time">{ new Date(ai.fileTime).toLocaleDateString()}</p>
            </li>
        </ol>
    </article>
    <style>
        .back {
            display: block;
            text-align: end;
        }

        .clear {
            font-family: serif;
            opacity: 0.5;
            position: relative;
            margin-right: -1.3em;
            cursor: pointer;
        }

        .articles-list a {
            display: flex;
            flex-direction: column;
            gap: 3px;
        }

        .articles-list a img {
            pointer-events: none;
        }

        .control {
            display: inline-flex;
        }

        .control label {
            font-size: small;
            display: inline-flex;
            align-items: center;
        }

        .control .order {

            margin-left: 0.5em;
        }

        .time {
            font-size: 0.65em;
            color: gray;
            font-style: normal;
            align-self: end;
        }

        .search-field {
            border-color: silver;
            border-radius: 0.5em;
            margin-left: 0.5em;
        }
    </style>
    <script>

        import {articleService} from "../../services/article-service";
        import {collectValues, populateFields} from "field-assist";
        import logo from '../../styles/resources/super-logo2.png'


        export default {
            defaultImage: logo,
            sortOrderAscending: true,
            hideUnfinished: true,
            filterString: null,
            async onMounted() {
                populateFields(this.$('.control'), {
                    sort: this.sortOrderAscending ? 'ascending' : 'descending',
                    hideUnfinished: this.hideUnfinished
                })
                const filterAndSortNode = this.$('.control')
                filterAndSortNode.addEventListener('click', e => {
                    const values = collectValues(filterAndSortNode);
                    this.sortOrderAscending = values.sort === 'ascending'
                    this.hideUnfinished = values.hideUnfinished
                    this.populateList()
                })
                this.populateList()
            },
            async populateList() {
                const entries = await articleService.getList('article', this.filterString, this.sortOrderAscending, this.hideUnfinished)
                this.update({entries})
                return entries
            },
            async updateSearch(event) {
                const string = event.target.value.trim()
                if (!string.length) {
                    this.update({searchResults: null})
                    return
                }
                this.filterString = string
                this.populateList()
            },
            clearSearch() {
                this.$('.search-field').value = ''
                this.filterString = null
                this.populateList()
            }
        }

    </script>
</articles>
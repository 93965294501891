// @ts-ignore
import MarkdownIt from 'markdown-it'
// @ts-ignore
// noinspection SpellCheckingInspection
import wikilinks from 'markdown-it-wikilinks'

import {router} from "@dharmax/state-router";

import {markdownItTable} from 'markdown-it-table'
import {attrs} from "@mdit/plugin-attrs";
import {container} from "@mdit/plugin-container";

const md: MarkdownIt = new MarkdownIt({
    html: true,
    xhtmlOut: true,
    linkify: true,
    typographer: true

});
md.use(wikilinks({
    baseURL: '/lib',
    uriSuffix: ''
}))
// @ts-ignore
md.use(attrs, {})
md.use(markdownItTable)
// @ts-ignore
md.use(container, {
    name: 'class',
    openRender: (tokens, index, options, _env, slf) => {
        const className = tokens[index].info.trim().split(' ')[1]
        tokens[index].attrJoin("class", className);
        return slf.renderToken(tokens, index, options);
    },
})

type Manifest = { [filename: string]: { meta: any, html: string | null } }

class ArticleService {

    private manifest: Manifest | null = null
    private loadedCounter = 0
    private MAX_CACHE = 20
    private cache: any[] = []

    constructor(private articleRootPath: string) {
        setInterval(() => {
            this.manifest = null
        }, 1000 * 60 * 60 * 2)
        this.createArticleListApiRoute();
    }


    private createArticleListApiRoute() {
        router.add(/^articlelist(\/urls)?$/, async (mode) => {

            const m = await this.getManifest()
            if (mode === '/urls') {
                let s = ''
                for (let a of Object.keys(m))
                    s += window.location.host + '/lib/' + a + '\n'
                document.body.innerHTML = s
            } else {
                const out = {...m}
                for (let a of Object.entries(out))
                    a[1].meta.url = window.location.host + '/lib/' + a[0]
                document.body.innerHTML = JSON.stringify(out)
            }
            document.head.innerHTML = ''
        })
    }

    async getArticle(articleName: string): Promise<{ meta: any; html: string | null }> {

        const manifest = await this.getManifest()
        let {meta, html} = manifest[articleName] || {meta: null, html: `${articleName} Not found`}

        if (meta && !html) {
            const raw = await readFileContent(meta.fileName, meta.entryType)
            html = meta.type == 'html' ? raw : md.render(raw)
            meta.wordCount = countWordsInHtml(html)
            manifest[articleName] = {meta, html}
            setTimeout(() => this.updateCache(articleName), 2000)
        }
        return manifest[articleName]

        async function readFileContent(filename: string, type: string): Promise<string> {
            const basePath = {article: '/articles/', 'blogPost': '/blog/'}[type];
            const res = await fetch(basePath + filename).then(raw => raw.text());
            const splitContent = res.split('\n---\n');

            if (splitContent.length > 1) {
                return splitContent.slice(1).join('\n---\n').trim();
            }

            return res.trim();
        }
    }

    async getList(type: string, filterString?: string, ascending?: boolean, hideUnfinished?: boolean) {
        const manifest = await this.getManifest()
        let list = Array.from(Object.values(manifest)).map(md => md.meta)
            .filter(md => md.entryType === type)

        filterString && (list = list.filter(ai => {
            try {
                return ai.name?.indexOf(filterString) >= 0
                    || ai.title?.indexOf(filterString) >= 0
                    || ai.synopsis?.indexOf(filterString) >= 0
                    || ai.keywords?.some((keyword: string) => filterString.split(/,\s*/).includes(keyword))
            } catch (e) {
                return true // for robustness
            }
        }))


        list = list?.sort((a1meta, a2meta) => {
            const pdiff = Number.parseFloat(a2meta.priority || '0') - Number.parseFloat(a1meta.priority || '0')
            if (pdiff)
                return Math.sign(pdiff)
            const d1 = a1meta.fileTime
            const d2 = a2meta.fileTime
            const r = ascending ? d2 - d1 : d1 - d2
            return isNaN(r) ? 0 : r
        })
        return list.filter(ai =>
            ai.list !== 'no' && (!hideUnfinished || ai.finished?.toLowerCase() !== 'no')
        )
    }


    async getManifest(): Promise<Manifest> {
        if (this.manifest)
            return this.manifest
        const manifestUrl = this.articleRootPath + '/markdownManifest.json';
        const rawManifest = await fetch(manifestUrl)
        this.manifest = (await rawManifest.json()) as Manifest
        return this.manifest
    }

    private updateCache(articleName: string) {
        // @ts-ignore
        this.manifest[articleName].meta.loadTime = new Date()
        this.cache.push(articleName)
        if (this.cache.length > this.MAX_CACHE) {
            const a = this.cache.shift()
            // @ts-ignore
            this.manifest[articleName].html = null
        }
    }
}

export const articleService = new ArticleService('')


function countWordsInHtml(htmlString: string): number {
    // Remove HTML tags
    const textContent = htmlString.replace(/<[^>]+>/g, "");

    // Split on whitespace and punctuation (excluding apostrophes)
    const words = textContent.split(/\s+|[!"#$%&()*+,-\./:;<=>?@\[\]^_{|}~`]/);

    // Filter out empty strings
    const filteredWords = words.filter((word) => word.trim() !== "");

    // Return the word count
    return filteredWords.length;
}


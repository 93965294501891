<recommendations>
    <h2>אנשים ממליצים</h2>
    <div class="container">
        <div id="recslider" class="keen-slider">
            <div each={r in recommendations} class="keen-slider__slide">
                <div>
                    <p>{r.text}</p>
                    <span>{r.from}</span>
                </div>
            </div>
        </div>
    </div>
    <style>
        :host {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }
        #rec-slider {
            width: 50em;
            border: 3px dot-dash yellow;
            height: 15em;
            /*font-family: serif;*/
            font-style: italic;
            overflow: hidden;
        }

        .keen-slider__slide {
            height: 80%;
            display: flex;
            justify-content: center;
            align-content: center;
        }

        .keen-slider__slide > div {
            padding: 1em;
            margin: 1em;
            width: 20em;
            background-blend-mode: color-dodge;
            background-color: rgba(246, 245, 209, 0.96);
            border-radius: 1em 0 1em 0;
            border: 1px solid black;

        }
    </style>


    <script>
        import 'keen-slider/keen-slider.min.css'
        import KeenSlider from 'keen-slider'

        export default {
            recommendations: [
                {
                    text: 'בזכות התרגולים איתך וההדרכה שעשינו בזמנו, חיי לא יהיו אותו דבר. הם השתנו לנצח ... ועל כך אני רוצה לומר שוב תודה ❤️ למסור לך את אהבתי ואת הערכתי אליך ולאישיות המסורה שלך 🙂',
                    from: 'י. ש"ט',
                    full: 'יועד שם-טוב'
                }, {
                    text: "בעיניי, שיחה עם אבי מביא בכל פעם ידע חדש והסתכלות על העולם שמשפרת אותי בכל פעם עוד. ממש ממליץ ליצור איתו קשר. ולו אם רק לדבר לרגע ולהכיר את האדם הנהדר הזה. זה לבד חוויה נהדרת.",
                    from: 'י.א',
                    full: 'יוסי אלפרסי'
                }, {
                    text: "הבנה עמוקה של תהליכים ושל טכניקות, יחד עם גישה חומלת ומסורה, כמו שיש אצל אבי, זה דבר נדיר מאוד ויקר מאוד. מומלץ בחום רב!!",
                    from: 'ג. ב.',
                    full: 'גבי בר'
                }, {
                    text: "אבי הוא מורה נהדר, ששינה לי את החיים, מאוד מומלץ.",
                    from: 'א. א.כ',
                    full: 'איציק ארזוני כהן'
                },
            ],
            onMounted() {
                const recsCount = this.recommendations.length
                const animation = {duration: recsCount * 2000, easing: t => t / 2}

                this.slider = new KeenSlider("#recslider", {
                    loop: true,
                    renderMode: "performance",
                    mode: "free-snap",
                    drag: true,
                    slides: {
                        perView: 3,
                        spacing: 10
                    },
                    created(s) {
                        s.moveToIdx(recsCount, true, animation)
                    },
                    updated(s) {
                        s.moveToIdx(s.track.details.abs + recsCount, true, animation)
                    },
                    animationEnded(s) {
                        s.moveToIdx(s.track.details.abs + recsCount, true, animation)
                    },
                })
            }

        }

    </script>


</recommendations>
<reviews>

    <recommendations/>

    <script>
        import Recommendations from '../recommendations.riot'

        export default {
            components: {
                Recommendations
            }
        }
    </script>

</reviews>
<about-me>

    <article>
        <a class="self-picture" href={SelfPicture} target="_blank">
            <img src={SelfPicture} alt="Avi Tshuva">
        </a>
        <p>
            שמי הוא אבי תשובה, או דהרמקס, או קונצ'וק צ'ימה נלג'ור (זה השם הבודהסיטי שניתן לי על ידי מורי הראשי). אני
            <b>
            ,מאמן-תודעה, מורה לדהרמה ומאמן לחיים ולשעבר - איש הייטק ויזם.
            </b>
            אני עוסק, חוקר
            ומתרגל בעולמות הרוח והגוף, בעיקר, היוגה, הבודהיזם על זרמיו השונים ואמנויות הלחימה, עוד משנות השמונים, ומלמד כבר מאמצע שנות התשעים - כן, בתקופה שבה בארצנו היו
            אלו
            דברים זרים, מוזרים, איזוטריים ולא פופולריים.
            <br>
            אולי דווקא בשל כך, המסע שלי היה מסע של חקירה אותנטית במיוחד, גם אם קשה ומאתגר יחסית, בגלל שלא היתה כל קהילה
            רלוונטית, הכרה חברתית, ובטח ובטח לא היתה הדרכה מיומנת אם בכלל, בשנים הראשונות.
            <br>
        </p>
        <p>
            למזלי, הקושי הגדול הפך ליתרון מיוחד - הוא זה שהבהיר לי את החשיבות שבדיוק ואת הקלות שבה אפשר לטעות בתרגול -
            גם הפיסי, גם הרוחני - וכך להיות, בין אם פשוט להיות לא יעיל, דרך להיות "לא בכיוון", ועד לטעויות מזיקות או מכאיבות של
            ממש.
            <br>
            ברבות השנים, זכיתי במורים שהם לא פחות ממופלאים - מורים אותנטיים, שמלבד מללמד אותי דברים מדהימים, נתנו לי את
            ברכתם והדרכתם לגבי התכנים והאופן בו אלמד.
        </p>
        <p>
            אחרי שנים רבות בהייטק, החלטתי לפרוש ובמקום זאת, להתמקד יותר בנתינה של הדבר החשוב, היקר והנחוץ ביותר שאני
            יודע לתת, בייחוד כשארצנו חוותה את הטראומה הגדולה של השביעי באוקטובר 2023.
            <br>
            ... מה שאלמד במסגרת המקצועית של מרכז לו-ג'ונג, זה לא בהכרח את הדרך הבודהיסטית, הדהרמה, מכיוון שזו טוטאלית מדי לרוב רובם של האנשים,
            שבסך הכל, לא מחפשים הארה (בינתיים) אלא שיפור
            צנוע (יחסית!) בחווית-החיים שלהם. אנשים שונים יקבלו את מה שמתאים ליכולתם והשלב בו הם נמצאים במסעם האישי.
            <br>
            מה כן אוכל לעשות? אוכל לרתום
            את הידע והטכניקות השונות מתורות אלה ואחרות, שהן רבות ורבות עוצמה, יחד עם ידע שרכשתי בתור איש הייטק מאוד מנוסה,  על
            מנת לתת גם לאדם "מן הישוב",
            דהיינו, לא בהכרח אדם שיש לו שאיפות רוחנית נעלות וטוטאליות כשל נזיר בודהיסטי או יוגי בהימלאיה - אדם כמוך, שפשוט רוצה לשפר את
            חייו, ארגון, משפחה או צוות, לקבל יותר שלווה, צלילות ובהירות לחיי הנפש, הרגש, לתוך העשייה שלו, ובכלל.
            <br>
            מי שכבר מתרגל - בין אם שנים ספורות או יותר - גם הוא ימצא כאן סביבת לימוד והעשרה, ומישהו שיזיז לו את הגבינה, כמו שכל מתרגל ראוי, עם אומץ ושאר-רוח, צריך שלרצות שיעשו לו, לפחות מפעם לפעם.
        </p>
        <p>
            אני חי בכפר-סבא, עם משפחתי הנפלאה והאוהבת להפליא: אשתי מיכל, ביתי שירה ובני שחר מוני, שצועדים איתי בדרך, כחברים-לדרך, כמוריי וכתלמידיי כאחד...
        </p>
        <p>
            ניתן לקרוא חומרים שכתבתי (בעיקר באנגלית) באתר
            שם נתתי תשובות למאות שאלות בנושא התודעה, הבודהיזם וכו' <a
                href="https://www.quora.com/profile/Avi-Tshuva/answers">Quora</a>
            .
            כמו כן, ניתן להזמין את הספר שכתבתי ("צעדים בדרך") על ידי פנייה אישית (זהו ספר למתרגלי דהרמה)
            .
        </p>
        <p>
            אפשר גם להציץ בפרופיל שלי
            <a href="https://www.linkedin.com/in/atshuva/">בלינקדאין</a>
        ...וכמובן, לקרוא כאן באתר את עשרות המיני-מאמרים והמאמרים, בהם איתגרתי את עצמי להנגיש בעדינות יתרה גם נושאים עמוקים ואיזוטריים, הקשורים כולם למה שאני עוסק בו בלו-ג'ונג, בין אם ישירות ובין אם בעקיפין.
        </p>
        לסיום, אני רוצה להזמין אותכם ואתכן ליצור קשר - גם אם לשיחה לא מחייבת, גם אם אתם רוצים עצה קטנה, או שאתם רוצים לדבר עם חבר לדרך מנוסה, אפילו בלי שום אלמנט עסקי - הכל באהבה גדולה, כי זה מה שמניע אותי, וזה מה שאני רוצה לתת!

    </article>
    <style>
        .self-picture img {
            width: 50%;
            height: auto;
            float: left;
            margin-right: 1em;
        }
    </style>

    <script>
        import SelfPicture from '../../styles/resources/self-picture2.jpg'

        export default {
            SelfPicture
        }
    </script>
</about-me>
import * as riot from 'riot';

// @ts-ignore
import App from './components/app.riot';


const mountApp = riot.component(App);
const el = document.getElementById('app')
// @ts-ignore
const app = mountApp(el, {})


/**
 * Fixes link opening so the router won't intercept what it shouldn't
 */
document.addEventListener('click', (event: MouseEvent) => {
    // Ensure it's a left click
    if (event.button !== 0) return;

    let target = event.target as HTMLElement;

    // Traverse up to find the closest anchor tag
    while (target && target.nodeName !== 'A') {
        target = target.parentNode as HTMLElement;
    }

    if (!target || target.nodeName !== 'A') return;

    const anchor = target as HTMLAnchorElement;
    const href = anchor.href;
    const isBlank = anchor.target === '_blank';

    if (href && isBlank) {
        // Open in a new tab and prevent default action
        window.open(href, '_blank');
        event.preventDefault();
    } else if (href) {
        // Handle internal SPA navigation here
        event.preventDefault();
        // Your SPA routing logic to navigate to href
    }
});



<md-article>

    <article id="content"></article>

    <script>

        import {articleService} from '../services/article-service.ts'

        export default {

            onMounted() {
                this.convert()
            },
            onUpdated() {
                this.convert()
                const content = this.$('#content')
                content && (content.scrollTop = 0)
            },
            async convert() {

                const article = this.props.article.meta ? this.props.article :  await articleService.getArticle(this.props.article)
                const contentNode = this.$('#content')
                contentNode.setAttribute( 'dir', article.meta.language === 'english' ? 'ltr' :'rtl')
                contentNode.innerHTML = article.html
            }
        }

    </script>
</md-article>
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set, get } from "firebase/database";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAzABsMHzzTVmvlO9rwVczSXIaW48xRPQ0",
    authDomain: "lojong1.firebaseapp.com",
    projectId: "lojong1",
    storageBucket: "lojong1.appspot.com",
    messagingSenderId: "399335937570",
    appId: "1:399335937570:web:82c9dea878ebf397041c7b",
    measurementId: "G-RZGEKFGQQQ",
    databaseURL: 'https://lojong1-default-rtdb.firebaseio.com/'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getDatabase(app)

export async function writeFormData( formData:any) {
    const userId = `${formData.name || 'noname'}-${formData.phone}`
    const dataRef = ref(db, 'contact-forms/'+ userId)
    formData.id = userId
    formData.time = (new Date()).toLocaleDateString()
    return set(dataRef, formData)

}
// export async function getAllContactForms(){
//     const dataRef = ref(db, 'contact-forms/')
//     return get(dataRef)
// }
<blog>
    <article>
        <h2>הבלוג</h2>
        <p>
            שימו לב: סרטוני וידאו אפשר למצוא ביוטיוב:
            <a href="https://www.youtube.com/@Lojong-studio">ערוץ היוטיוב שלנו</a>
            <br>
            ואת המאמרים אפשר למצוא
            <a href="/articles">
                פה!
            </a>
            <br>
            ואת ההארה והאושר אפשר למצוא, נקודה!
        </p>

        <div class="blog-view">
            <div class="last-post-pane">
                <h3>פוסט אחרון:</h3>
                <md-article if={state.mostRecent} article={state.mostRecent?.name}></md-article>
            </div>
            <div class="blog-list">
                <h3>כל הפוסטים:</h3>
                <label>
                    <input class="search-field" type="text" onkeyup={updateSearch} placeholder="חיפוש"
                           aria-label="search"
                           }>
                    <span role="button" class="clear" onclick={clearSearch}>X</span>
                </label>
                &nbsp;
                <div class="control">
                    <div class="order">
                <span><label><input type="radio" name="sort" ref="sort" value="ascending"
                                    checked>חדשים קודם</label></span>
                        <span><label><input type="radio" name="sort" ref="sort"
                                            value="descending">ישנים קודם</label></span>
                    </div>
                </div>

                <ol class="articles-list">
                    <li each={ ai in (state.searchResults || state.entries) }
                        class="{ `blog-thumbnail + ${ai.finished ==='no'? 'unfinished': 'ready'}` }">
                        <a href={"/lib/"+ai.name}>{ai.title || ai.name}
                            {new Date(ai.fileTime).toLocaleDateString()} </a>
                        <div class="synopsis">{ai.abstract || ai.synopsis || ''}</div>
                    </li>
                </ol>
            </div>
        </div>
    </article>
    <style>
        .back {
            display: block;
            text-align: end;
        }

        .clear {
            font-family: serif;
            opacity: 0.5;
            position: relative;
            margin-right: -1.3em;
            cursor: pointer;
        }

        .blog-view {
            display: flex;
            gap: 1em;
        }
        .control {
            display: inline-flex;
        }

        .control label {
            font-size: small;
            display: inline-flex;
            align-items: center;
        }

        .control .order {

            margin-left: 0.5em;
        }

        .time {
            font-size: 0.65em;
            color: gray;
            font-style: normal;
            align-self: end;
        }

        .search-field {
            border-color: silver;
            border-radius: 0.5em;
            margin-left: 0.5em;
        }
    </style>
    <script>

        import {articleService} from "../../services/article-service";
        import {collectValues, populateFields} from "field-assist";
        import logo from '../../styles/resources/super-logo2.png'
        import MdArticle from '../md-article.riot'


        export default {
            components: {
                MdArticle
            },
            defaultImage: logo,
            sortOrderAscending: true,
            hideUnfinished: true,
            filterString: null,
            async onMounted() {
                populateFields(this.$('.control'), {
                    sort: this.sortOrderAscending ? 'ascending' : 'descending',
                    hideUnfinished: this.hideUnfinished
                })
                const filterAndSortNode = this.$('.control')
                filterAndSortNode.addEventListener('click', e => {
                    const values = collectValues(filterAndSortNode);
                    this.sortOrderAscending = values.sort === 'ascending'
                    this.hideUnfinished = values.hideUnfinished
                    this.populateList()
                })
                return this.populateList()
            },
            async populateList() {
                const entries = await articleService.getList('blogPost', this.filterString, this.sortOrderAscending, this.hideUnfinished)
                const descendingList = this.sortOrderAscending ? await articleService.getList('blogPost', null, false, true) : entries
                const mostRecent = descendingList[0]
                this.update({entries,mostRecent})
                return entries
            },
            async updateSearch(event) {
                const string = event.target.value.trim()
                if (!string.length) {
                    this.update({searchResults: null})
                    return
                }
                this.filterString = string
                return this.populateList()
            },
            clearSearch() {
                this.$('.search-field').value = ''
                this.filterString = null
                this.populateList()
            }
        }

    </script>
</blog>
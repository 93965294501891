<recent-articles>
    <div id="article-slider" class="keen-slider">
        <div each={ai in state.articles} class="keen-slider__slide">
            <div class="{ `article-thumbnail + ${ai.finished ==='no'? 'unfinished': 'ready'}` }" onclick={navigate(ai)}>
                <div class="blog-post" if={ai.entryType === 'blogPost'}>
                    <a href={"/lib/"+ai.name}>
                        <h2>
                            רשומה בבלוג
                            <br>
                            {ai.published_time}
                            <br>
                            {ai.title || ai.name}
                        </h2>
                    </a>
                    <div class="synopsis">{ai.abstract || ai.synopsis || ''}</div>
                </div>
                <template if={ai.entryType === 'article'}>
                    <img src={ai.image || defaultImage} alt="">
                    <a href={"/lib/"+ai.name}>{ai.title || ai.name}</a>
                    <div class="synopsis">{ai.abstract || ai.synopsis || ''}</div>
                    <div if={ai.finished ==='no'}>[מאמר עדיין בעבודה]</div>
                </template>
            </div>
        </div>
        <DIV class="to-all-articles">
            <a href="/articles">לכל המאמרים</a>
            <a href="/blog">לכל הבלוג</a>
        </DIV>
        <style>
            :host {
                width: 100%;
                height: 100%;
            }

            .to-all-articles {
                text-align: end;
            }
            .blog-post {
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                justify-items: center;
            }

            .blog-post a {
                text-align: center;
                display: flex;
                justify-content: center;
                justify-items: center;
            }
            .blog-post > * {

            }

            .keen-slider__slide img {
                @media (min-width: 1400px) {
                    width: 50%;
                }
                width: 80%;

            }

            .keen-slider__slide > div {
                padding: 1em;
                margin: 1em;
                @media (min-width: 1400px) {
                    width: 60%
                }
                width: fit-content;
                background-color: #4a90e220;

            }
        </style>


        <script>

            import 'keen-slider/keen-slider.min.css'
            import KeenSlider from 'keen-slider'
            import {articleService} from "../services/article-service";
            import logo from '../styles/resources/super-logo2.png'


            export default {
                defaultImage: logo,
                async onMounted() {
                    const articles = await this.loadArticleList()
                    const recsCount = articles.length
                    const animation = {duration: recsCount * 2000, easing: t => t / 2}
                    this.update({articles})
                    this.slider = new KeenSlider("#article-slider", {
                        loop: true,
                        renderMode: "performance",
                        mode: "free-snap",
                        drag: true,
                        slides: {
                            perView: 2,
                            spacing: 5
                        },
                        created(s) {
                            s.moveToIdx(recsCount, true, animation)
                        },
                        updated(s) {
                            s.moveToIdx(s.track.details.abs + recsCount, true, animation)
                        },
                        animationEnded(s) {
                            s.moveToIdx(s.track.details.abs + recsCount, true, animation)
                        },
                    })
                },
                navigate(ai) {
                    return () => window.open('/lib/' + ai.name, '_black')

                },
                async loadArticleList() {
                    const articles = await articleService.getList('article')
                    const blog = await articleService.getList('blogPost')
                    let list = blog.concat(articles)

                    const fileTime = md => {
                        const v = md.modified_time || md.published_time || md.mtime || md.birthtime
                        return new Date(v)
                    }
                    list = list?.sort((a1, a2) => {
                        const d1 = fileTime(a1)
                        const d2 = fileTime(a2)
                        const r = this.sortOrderAscending ? d2 - d1 : d1 - d2
                        return isNaN(r) ? 0 : r
                    })
                    return list.filter(ai => ai.list !== 'no' && ai.finished !== 'no')
                }
            }

        </script>


</recent-articles>
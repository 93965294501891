<events>
    <article>
        <h1>לוח אירועים</h1>

        <ol>

            <li each={e in state.events}>
                <img src={e.imageUrl || getImage(e)} alt="">

                <div class="when" if={e.next}>

                    <b> {e.weekday} {e.next.toLocaleDateString()} </b> &nbsp; &nbsp;
                    &nbsp;
                    &nbsp;
                    בשעה
                    {e.time}
                    &nbsp;
                    <span if={e.recurrenceType }>(אירוע
                        <b> {e.recurrenceType }</b>)
                    </span> &nbsp;
                </div>
                <h2>
                    {e.name}
                </h2>
                <h3>
                    {e.subtitle}
                </h3>
                <p class="announcement {e.announcementType}" if={e.specialAnnouncement}>
                    {e.specialAnnouncement}
                </p>

                <details>
                    <summary>
                        פרטים
                    </summary>
                    <p style="white-space: pre-line;">
                        {e.description}
                    </p>
                    <p if={e.eventType !== EventType.Announcement}>
                        <b>עלות: </b>
                        {e.costString}
                    </p>
                    <p if={e.equipment}>
                        <b>
                            יש להביא:
                        </b>
                        &nbsp;
                        {e.equipment}
                    </p>
                    <p>
                        <b>מיקום: </b>
                        {e.location || 'שאל'}
                    </p>
                    <p if={e.whatsappLink}>
                        <a href="{e.whatsappLink}"> <b>
                            וואצאפ שקט לעדכונים
                        </b>
                        </a>
                    </p>
                    <p if={e.telegramLink}>
                        <a href="{e.telegramLink}"> <b>
                            טלגרם שקט לעדכונים
                        </b>
                        </a>
                    </p>
                    <p if={e.otherUrl}>
                        <a href="{e.otherUrl}"> <b>
                            קישור
                        </b>
                        </a>
                    </p>
                    <p class="liability" if={e.physical}>
                        הגעה להשתתפות הינה כהצהרה שאתם בריאים מספיק לפעילות מסוג זה, וכל אחריות לגבי פציעות היא עליכם
                        בלבד
                    </p>
                </details>
            </li>
        </ol>
    </article>

    <script>

        import parkYogaImage from '../../styles/resources/park-yoga.jpeg'
        import groupMeetingImage from '../../styles/resources/group-meeting.jpeg'
        import practiceImage from '../../styles/resources/practice.png'
        import announcementImage from '../../styles/resources/announcement.png'
        import {eventManager, CostType, EventType} from '../../services/studio-events-service.ts'

        export default {
            parkYogaImage,
            CostType,
            EventType,
            async onMounted() {

                const events = eventManager.getNextEvents(3)
                this.update({events})
            },

            getImage(e) {
                switch (e.type) {
                    case EventType.Announcement:
                        return announcementImage
                    case EventType.Practice:
                        return practiceImage
                    case EventType.Teaching:
                    case EventType.GroupMeeting:
                        return groupMeetingImage
                    case EventType.OpenPractice:
                        return parkYogaImage

                }
            },
        }

    </script>

</events>
<menu-manager>
    <div class="openner" onclick="{toggle}">&equiv;</div>

    <span class="the-menu {state.open}">
    <slot/>
    </span>


    <script>
        import dispatcher from "@dharmax/pubsub";

        export default {
            state: {
                open: 'open'
            },
            onMounted() {
                this.updateState(window.location.href)
                dispatcher.on('state:changed', e => this.updateState(e.data.route))
            },
            onUpdated() {
                this.changeState(window.location.href)
            },
            changeState(route) {
                const nodes = this.$$('a')

                nodes.forEach(n => {
                    if (n.hash.substring(1).match(route) || n.href === route)
                        n.classList.add('selected')
                    else
                        n.classList.remove('selected')
                })
            },
            updateState(route) {
                this.changeState(route);
                this.update({open: 'closed'})
            },
            toggle() {
                const isOpen = this.state.open && this.state.open !== 'closed'
                this.update({open: isOpen ? 'closed' : 'open'})
            }
        }


    </script>

</menu-manager>
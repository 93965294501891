<event-ticker>

    <div class="container">
        <div id="rec-slider" class="keen-slider">
            <div each={e in state.events} class="keen-slider__slide">
                <a href="/events" target="_blank">
                    <b if={e.next}> {e.weekday} {e.next.toLocaleDateString()|| ''} - </b> &nbsp; {e.name}
                    <span if={e.specialAnnouncement} class="attention">&#9888;</span>
                </a>
            </div>
        </div>
    </div>

    <style>
        :host {
            margin-top: 1em;
            height: 3em;
            line-height: 3em;
            width: 100vw;
            position: absolute;
            background-color: #ffffff80;
        }

        .container div a {
            color: var(--opaque-background-color);
            opacity: 0.8;
            font-size: 1.5rem;
            padding: 2px;
            border: 1px solid var(--accent-color);
            border-radius: 0.5em;
            background-color: var(--accent-color);
            text-wrap: nowrap;
        }
        .attention {
            color: black;
            text-effect: emboss;
            font-weight: bold;
        }

    </style>

    <script>
        import {eventManager,EventStatus} from '../services/studio-events-service.ts'

        import 'keen-slider/keen-slider.min.css'
        import KeenSlider from 'keen-slider'

        export default {

            async onMounted() {
                const now = new Date()
                const events = eventManager.getNextEvents(2).filter(e=> e.status === EventStatus.Active )
                this.update({events})

                const recsCount = events.length
                const animation = {duration: recsCount * 3000, easing: t => t / 3}

                setTimeout(() => {
                    this.slider = new KeenSlider("#rec-slider", {
                        loop: true,
                        renderMode: "performance",
                        mode: "free-snap",
                        drag: false,
                        slides: {
                            perView: 2,
                            // spacing: 10
                        },
                        created(s) {
                            s.moveToIdx(recsCount, true, animation)
                        },
                        updated(s) {
                            s.moveToIdx(s.track.details.abs + recsCount, true, animation)
                        },
                        animationEnded(s) {
                            s.moveToIdx(s.track.details.abs + recsCount, true, animation)
                        },
                    })
                }, 2000)


            }
        }

    </script>

</event-ticker>
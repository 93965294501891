<videos-panel>

    <div class="carousel">

        <div each={video in state.videos}>
            <img src="${video.thumbnail}" alt="${video.title}">
            <h3>${video.title}</h3>
            <a href="https://www.youtube.com/watch?v=${video.videoId}" target="_blank">Watch on YouTube</a>
        </div>

    </div>

    <script>
        const API_KEY = 'AIzaSyAzABsMHzzTVmvlO9rwVczSXIaW48xRPQ0'
        const CHANNEL_ID = 'https://www.youtube.com/@Lojong-studio';
        const MAX_RESULTS = 50;

        export default {

            async onMounted() {
                this.update({videos: await fetchYouTubeVideos()})
            }
        }

        async function fetchYouTubeVideos() {
            const url = `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=${MAX_RESULTS}`;

            try {
                const response = await fetch(url);
                const data = await response.json();

                if (data.items) {
                    return data.items.map(item => ({
                        videoId: item.id.videoId,
                        title: item.snippet.title,
                        thumbnail: item.snippet.thumbnails.high.url,
                    }));
                } else {
                    console.error('No items found in the response:', data);
                    return [];
                }
            } catch (error) {
                console.error('Error fetching YouTube videos:', error);
                return [];
            }
        }

    </script>


</videos-panel>
<contact-form class={state.asPage ? 'as-page':'floating'}>

    <div>
        <button if={!state.asPage} onclick={props.closeCB} class="close">X</button>
        <section class="form-main">
            <section id="contact-form" class="form">
                <h1>נשמח לשמוע ממך!</h1>
            </section>
            <div class="logo">
                <img src={logo} alt="lojong logo">
            </div>
        </section>
        <button class="contact-btn" onclick={sendForm}>
            שלח
        </button>


    </div>

    <style>

        :host.floating {
            position: fixed;
        }

        :host.as-page {
            box-shadow: none;
        }

        :host .autoform div {
            padding: 0.2em 0;
            display: grid;
            grid-template-columns: 6em 12em;
        }

        :host [ref=message] {
            max-width: 36vw;
            width: 150%;
            height: 5em;
        }

        .close {
            position: absolute;
            top: 1em;
            left: 1em;
        }

        .thanks {
            position: fixed;
            display: flex;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: transparent;
            justify-content: center;
            align-items: center;
        }
        .thanks>div {
            width:fit-content;
            height: fit-content;
            padding: 6em;
            border-radius: 1em;
            border: 2px solid var(--primary-color);
            background-color: var(--opaque-background-color);
            color: var(--primary-color);
            text-align: center;
            box-shadow: 1em 1em 1em 0.25em gray;
        }

        button.contact {
            border: 2px solid var(--accent-color);
            transform: rotateZ(-45deg);
            font-size: large;
            padding: 5px;
            transition: transform 1s ease;
        }
        button.contact:hover {
            transform: rotateZ(0deg) scale(1.5);
            position: relative;
            left: 1em;
        }

    </style>
    <script>

        import {collectValues, AutoForm, populateFields} from 'field-assist'
        import logo from '../styles/resources/logo.svg'
        import {writeFormData} from "../services/firebase";
        import {stateService} from "../services/state-service";

        export default {

            state: {
                errors: ''
            },
            logo,
            onMounted() {
                window.contactForm = this

                this.createForm()
                this.update({asPage: stateService.appState.mode === 'as-page'})
            },
            onUnmounted() {
                window.contactForm = null
            },
            createForm() {
                const form = new AutoForm({
                    name: {
                        label: 'שם מלא',
                        attributes: {minlength: '2', maxlength: '32', required: ''}
                    },
                    'phone': {
                        label: 'טלפון',
                        attributes: {type: 'tel', pattern: '0[1-9][0-9]-?[0-9]{7}', class: 'phone'}
                    },
                    forWhom: {
                        label: 'מי/מה אני',
                        componentType: 'select',
                        options: ['יחיד - בלי רקע רלוונטי רב',
                            'יחיד - מטפל/מאמן/מדריך',
                            'יחיד - לא חדש לתחום',
                            'זוג/משפחה',
                            'קבוצה/ארגון'
                        ],
                        attributes: {required: ''},
                        defaultValue: 'יחיד - בלי רקע רלוונטי רב'
                    },
                    'email': {
                        label: 'דוא"ל',
                        attributes: {type: 'email'}
                    },
                    'type': {
                        label: 'מהות הפניה',
                        componentType: 'select',
                        options: ['מעוניין במידע נוסף',
                            'הערה לגבי האתר'
                        ],
                        defaultValue: 'מעוניין במידע נוסף'
                    },
                    'message': {
                        label: 'הודעה',
                        componentType: "textarea",
                        validator: (v) => v.length < 1024
                    },
                    age: {
                        label: 'גיל'
                    }

                })
                const values = {
                    'name': '',
                    'phone': '',
                    'email': '',
                    forWhom: 'יחיד - בלי רקע רלוונטי רב',
                    'type': 'מעוניין במידע נוסף',
                    age: 0,
                    'message': 'אשמח לשמוע עוד',

                }
                const formNode = form.render(values, this.$('.form'))
                populateFields(formNode, values)

            },
            async sendForm() {
                const node = document.getElementById('contact-form')
                const formData = collectValues(node)
                if (formData._errors)
                    return

                await writeFormData(formData)
                window.location.href="/thanks"
            }

        }
    </script>

</contact-form>
<videos>

    <h1>Videos</h1>
    <videos-panel/>


<script>
    import VideosPanel from '../videos-panel.riot'
    export default {
        components: {
            VideosPanel
        }
    }

</script>

</videos>

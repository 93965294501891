<thanks>

    <div class="thanks">
        <div>
            <h1>תודה רבה!</h1>
            <p></p>
            <h2> נהיה בקשר בקרוב!</h2>
        </div>
    </div>
    <style>
        :host {
            background: var(--opaque-background-color);
            margin:  var(--standard-space);
            padding: var(--standard-space);
            text-align: center;

        }
    </style>
    <script>

        export default {
            onMounted() {

                window.dataLayer = window.dataLayer || [];
                const entry = {
                    'event': 'form_complete',
                    "phone": "+972" + sessionStorage.formPhoneNumber,
                }

                window.dataLayer.push(entry);


            }
        }

    </script>
</thanks>
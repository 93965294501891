<the-process>
    <article>
        <img src="/articles/images/process2.webp" alt="main-pic" srcset="">

        <h1>על התהליך</h1>
        <p>
            יש דברים רבים הדומים לכאורה למה שאנו עושים פה, כגון "אימון אישי", או תרפיות פסילוגיסטיות שונות, הן באחד על אחד
            והן במפגשים קבוצתיים.
            <br>
            במרכז לו-ג'ונג, מבחינת המסגרת, זה די דומה: יהיו מפגשים של אחד על אחד באופן קבוע, ובשלבים מסויימים, גם מפגשים
            קבוצתיים מכמה סוגים.
        <p>פה הדמיון נגמר, פחות או יותר. ההבדלים הם בכלים, בגישה ובמטרות; אנו משתמשים בכלים רבי-עוצמה מהמסורות העתיקות
            ומשיטות מודרניות, הגישה מתבססת על אידאלים עמוקים ונשגבים, רוויים ברצון והתכוונות עזים להעניק ולעזור לכם, והמטרות מרחיקות לכת
            הרבה מעבר להפיכתו של אדם לאדם נורמטיבי או מעט יותר מכך.
        </p>
        <p>
            כפועל יוצא, גם האפקטיביות גדולה בהרבה: התוצאות וקצב ההתקדמות הם שונים בתכלית!ן
        </p>
        <p>
            חשוב לציין כי התהליך
            <b>מותאם אישית</b>
            לכל אחד מכם (ולכל קבוצה וארגון).
            <a href="/lib/about-the-tools">
                הכלים, התרגולים וההנחיות
            </a>
            האישיות יוגדרו ויעודכנו הן בהתאם למטרות הראשיות, הכלליות (שלום-פנימי, בהירות
            ואיזון) והן בהתאם למטרות הספציפיות,
            של האדם או הקבוצה.

        <h2>דקות, פתיחות וכנות</h2>
        <p>
            דגש רב מושם על
            <b>
                דקויות</b>
            ועל
            <b>
                כנות
            </b>
            בכל הרבדים.
            כנות פנימית ובהירות הן כמעט אותו הדבר. הניגוד של בהירות היא בלבול ועכירות. אלו מביאים סבל באופנים וצורות
            שונות.
            <br>
            הבחנה דקה וריאליזם הם מהיסודות של דרכנו והן הנוגדן של הבלבול והעכירות. זו, למעשה, בהירות, במילים אחרות.
            אור פנימי, אם תרצו.
        </p>
        <h2>אחריותכם</h2>
        <p>
             אל נא תשגו לחשוב שהמפגשים לבדם יספיקו, ולכם אין כל אחריות לתהליך -
            אפילו ביקור אצל רופא שיניים לא פוטר אף אחד מצחצוח שיניים.
            בייחוד לא אם אתם מכוונים טיפה גבוה יותר מבריאות דנטלית...
            כן, ישנם כאלה שצריכים יותר תמיכה וכאלה שפחות, אבל מאמץ אישי הוא הכרחי. אנו נלמד גם איך (וכמה) לוקחים אחריות
            על
            התפתחותינו האישית, על המשפחה והקהילה ועל חובותינו האחרים.
            לכן, תהינה משימות, יהיו "שיעורי בית" לא מעטים - אך פריים ותועלתם יורגשו מיידית!
            <br>
            לכן, התהליך אינו מוגדר כטיפול, למרות שיכולים להיות בו אלמנטים טיפוליים. זהו ליווי, מנטורינג, הכשרה, אימון, השבחה, העצמה וגם קצת טיפול.

        </p>
        <h3>החיים, התרגול ואנחנו כמערכת אינטגרלית</h3>
        <p>
            במסגרת המטרות האישיות, תנתן התייחסות רבה לכל דבר רלוונטי, כמו למשל אלמנטים משפחתיים, חברתיים, העיסוקים,
            ההשקפות והדעות, הפרנסה, וכדומה.
            בהירות, שקט פנימי וגישה חיובית הם טובים ומועילים באופן
            אוניברסלי, אך חלק מהכלים והדרך המיטבית להשתמש בם, הוא לפעמים תלוי בנסיבות האישיות כגון אלה, וכמובן -
            נסיבות לא-מיטיבות ננסה להיטב, במידה ובאופן שנוכל לעשות כה.
            <br>
            נבצע תרגולים "פורמליים", שהם כמו "טקסים", דהיינו - הם נעשים באופן מסודר ושיטתי עושים וכשהם נעשים, מתרכזים רק
            בהם, אך
            בנוסף יהיו תרגולים "בלתי-פורמליים",
            אותם מיישמים בכל מיני הזדמנויות במהלך היום, בין אם בעצירה קצרצרה לפני, למשל, שיושבים לאכול, ובין תוך כדי
            עשיה אחרת, יומיומית, כלשהי.
            החשיבות והאפקטיביות של שני אלה גם יחד הינה גדולה ומהותית ואחריותכם - ליישם זאת.
        </p>
        <h2> עקרון הליווי (או - אחריותנו שלנו)</h2>
        <p>
            בין אם אתם כבר מתרגלים דברים רלוונטים כבר שנים רבות, או שהכל חדש לכם, עוד זוג עיניים, יד מכוונת, עוד לב ושכל
            מיומנים,
            יכולים להועיל במידה עצומה; דיוק ודקות יכולים לעשות הבדל גדול מאוד גם
            בטיב ובאיכות ההתקדמות, גם בקצב וגם לחסוך קושי ואף כאב מיותר. במהלך התהליך, החיפוש אחר שגיאות ותיקונן רלוונטי
            תמיד. עם ההעמקה, היכולת לדייק את פעולותינו הפנימיות והחיצוניות גדלה, וכאן
            בא המקום של הדרכה מיומנת לאורך זמן.
            התהליך הוא ממושך. זהו מסלול.
            <i>
                התרגול מכה שורש ונותן את פריו, כאשר הוא מבוצע
                כראוי, בהתמדה ולאורך זמן.
            </i>
            <br>
        <p class="indent">
            <b>
                כראוי -
            </b>
            עשיית הדבר הנכון, באופן הנכון, תיקון, דיוק, שכלול, הפשטה באופן שוטף;
            <br>
            <b>
                בהתמדה -
            </b>
            יש לעשות את האימון, על המשימות, התרגולים וכדומה בתדירות הנכונה;
            <br>
            <b>
                לאורך זמן -
            </b>
            זה לא שאין קיצורי דרך... יש... ונשתמש בהם, אבל עדיין - זהו מסלול וזו דרך שיש לעשותה
        </p>
        <p>
            <b>אחריותי האישית</b>
            היא לעזור בעניין של
            <b>
                כראוי
            </b>
            על ידי ההדרכה, הליווי, התמיכה, הקשב, האהבה, וכל הכלים שברשותי, ולספק כמיטב יכולתי, את האפשרות לתת זאת
            <b>
                לאורך זמן. ההתמדה - עליכם...
            </b>
        </p>
        <br>
        <p>
            בואו והצטרפו למסע המופלא!
        </p>

    </article>
    <script>
        export default {}
    </script>

</the-process>
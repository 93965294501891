<app dir="rtl" class={state.colorScheme}>
    <header class="header">
        <div class="title">
            <title onclick={goHome} >
                לו-ג'ונג -
                מכון לאימון תודעה ושלום פנימי
            </title>
            <div class="logo" onclick={()=>window.location.href='/welcome'}>
                <img src={logo} alt="lojong logo">
            </div>
        </div>
        <menu-manager>
            <nav class="nav">
                <li><a href="/home">ראשי</a></li>
                <li><a href="/the-process">התהליך</a></li>
                <li><a href="/lib/about-the-tools">הכלים</a></li>
                <li><a href="/articles">תכנים וידע</a>
                    <ul>
                        <li><a href="/articles">מאמרים</a></li>
                        <li><a href="/blog">בלוג</a></li>
                        <li></li>
                    </ul>

                </li>
                <li><a href="/for-whom">למי זה מיועד</a></li>
                <li><a href="/plans">תכניות ומחירים</a></li>
                <li><a href="/about-me">אודותי</a></li>
                <li><a href="/reviews">המלצות</a></li>
                <li><a href="/events">אירועים</a></li>
                <li class="contact-menu-item"><a href="/contact">צור קשר</a></li>
            </nav>
        </menu-manager>
        <event-ticker/>
    </header>
    <div class="main-container">
        <a href="https://wa.me/972544744969?text=מתעניין בלו-ג'ונג" target="_blank" class="whatsapp-link" aria-label="open whatsapp">
            <button class="contact">
                דבר איתי בוואטצאפ!
            </button>
        </a>

        <main class="main background" is={state.currentPage}/>
        <contact-form if={state.contactFormOpen} closeCB={()=>openContactForm(false)}/>
    </div>
    <footer>
        <div class="info">
            <div class="business-name">
                <p class="name">מרכז לו-ג'ונג</p>
                <p> אזור השרון, כפר סבא</p>
            </div>
            <div class="contact-details">
                <div class="facebook-link">
                    <a href="https://www.facebook.com/lojongstudio/" target="_blank" aria-label="open facebook page">Facebook</a>
                </div>
                <div class="facebook-link">
                    <a href="https://www.youtube.com/@Lojong-studio" target="_blank" aria-label="open facebook page">YouTube</a>
                </div>
                <a href="tel:+972-54-4799404" target="_blank" aria-label="main phone">054-4799404</a>
                <a href="tel:+972-54-4744969" target="_blank" aria-label="secondary phone">054-4744969</a>
                <a href="mailto:dharmax@lojong-studio.com" target="_blank" aria-label="email">info@lojong-studio.com</a>

            </div>
            <div class="more">
                <span style="font-weight: bold">עוד:</span>
                <br>
                <li><a href="/the-process">התהליך</a></li>
                <li><a href="/articles">תכנים וידע</a></li>
                <li><a href="/lib/about-the-tools">על הכלים</a></li>
                <li><a href="/for-whom">למי זה מיועד</a></li>
                <li><a href="/about-me">אודותי</a></li>
            </div>
        </div>
        <div class="contact-buttons">
            <a href="https://wa.me/972544744969?text=מתעניין בלו-ג'ונג" target="_blank">
                <button class="contact">
                    דבר איתי בוואטצאפ!
                </button>
            </a>
            <button class="contact" onclick={openContactForm}>צור קשר</button>
        </div>
        <div>
            <img class="small-self-pic" src={selfPic} alt="avi-tshuva-pic">
        </div>
    </footer>

    <style>
        :host {
            border: 1px solid silver;
        }

        .info {
            display: flex;
            @media (min-width: 1500px) {
                gap: 3em;
            }
        }

        .contact-details {
            display: flex;
            flex-direction: column;
        }

        .contact-buttons {
            display: flex;
            flex-direction: column;
            gap: 2px;
        }

        .business-name {
            font-weight: bold;
        }

        .contact-details .facebook-link {
            padding: 2px;
            margin-bottom: 2px;
            border: 1px solid var(--opaque-background-color);
            border-radius: 0.5em;
            text-align: center;

        }
    </style>
    <script>

        import Home from './pages/home.riot'
        import TheProcess from './pages/the-process.riot'
        import TheTools from './pages/the-tools.riot'
        import Articles from './pages/articles.riot'
        import ArticleDisplay from './pages/article-display.riot'
        import AboutMe from './pages/about-me.riot'
        import ForWhom from './pages/for-whom.riot'
        import Plans from './pages/plans.riot'
        import Reviews from './pages/reviews.riot'
        import Welcome from './pages/welcome.riot'
        import WelcomeOrg from './pages/welcome-org.riot'
        import Thanks from './pages/thanks.riot'
        import Videos from './pages/videos.riot'
        import Events from './pages/events.riot'
        import Blog from './pages/blog.riot'

        import logo from '../styles/resources/logo.svg'
        import MenuManager from './menu-manager.riot'
        import ContactForm from './contact-form.riot'
        import {stateService, dispatcher} from "../services/state-service";
        import {articleService} from "../services/article-service";

        import defaultPageImage from '../styles/resources/super-logo2.png'
        import selfPic from '../styles/resources/avi-a.jpg'

        import EventTicker from './event-ticker.riot'



        export default {
            logo,
            selfPic,
            components: {
                EventTicker,
                Home,
                TheProcess,
                TheTools,
                AboutMe,
                Articles,
                MenuManager,
                ArticleDisplay,
                ContactForm,
                ForWhom,
                Reviews,
                Plans,
                Welcome,
                WelcomeOrg,
                Thanks,
                Events,
                Videos,
                Blog
            },
            state: {
                colorScheme: 'light-mode',
                contactFormOpen: false
            },
            openContactForm(value = true) {
                this.update({contactFormOpen: value && !window.contactForm})
            },
            goHome() {
                window.location.href = '/home'
            },
            onMounted() {
                articleService.getList()
                stateService.addState('home')
                stateService.addState('welcome')
                stateService.addState('welcome-org')
                stateService.addState('thanks','thanks','thanks/%')
                stateService.addState('the-process')
                stateService.addState('the-tools')
                stateService.addState('about-me')
                stateService.addState('articles')
                stateService.addState('blog')
                stateService.addState('for-whom')
                stateService.addState('plans')
                stateService.addState('videos')
                stateService.addState('reviews')
                stateService.addState('events')
                stateService.addState('article', 'article-display', 'lib/%')
                stateService.addState('contact', 'contact-form', 'contact', 'as-page')
                dispatcher.on('state:changed', (e) => this.handlePageChange(e))
                stateService.start()
                stateService.restoreState('home')

            },

            handlePageChange(event) {
                (async () => {
                    const currentPage = event.data.pageName
                    const fbCommentsNode = document.getElementsByClassName('fb-comments')[0];
                    let description, articleTitle;
                    let ogType = 'website'
                    let image = defaultPageImage
                    if (currentPage === 'article-display') {
                        ogType = 'article'
                        const articleName = stateService.context
                        const articleMd = (await articleService.getArticle(articleName)).meta
                        articleTitle = articleMd?.title || articleName
                        description = articleMd && (articleMd.synopsis || articleMd.abstract) || ` וויקי-מאמר: ${articleTitle}`
                        image = articleMd.image || image;
                        ['section', 'published_time', 'modified_time', 'tags'].forEach(ogTag => {
                            const tagValue = articleMd && articleMd[ogTag]
                            if (tagValue)
                                this.$('meta[property="og:article:' + ogTag + '"]')?.setAttribute('content', tagValue)
                        })

                        fbCommentsNode && fbCommentsNode.setAttribute('data-href', window.location.href)
                        fbCommentsNode && (fbCommentsNode.style.visibility = 'visible')

                    } else {
                        description = `${pageName(currentPage)} ${stateService.context || ''} אימון לבהירות, שלווה ואיזון בשיטות בודהיסטיות ויוגיות `
                        fbCommentsNode && (fbCommentsNode.style.visibility = 'hidden')
                    }


                    const title = articleTitle || `${pageName(currentPage)} לו-ג'ונג `
                    const fields = {
                        'og-title': title,
                        'og-type': ogType,
                        'og-image': image,
                        'page-description': description,
                        'og-description': description,
                        'page-url': window.location.href
                    }
                    for (let [id, content] of Object.entries(fields)) {
                        const node = document.getElementById(id)
                        node?.setAttribute('content', content)
                    }
                    const titleNode = document.getElementById('page-title')
                    titleNode && (titleNode.textContent = title)

                    const detailsNode = document.getElementById('ldjson')
                    detailsNode.textContent = `
{
        "@context": "http://schema.org",
        "@type": "LocalBusiness",
        "name": "לו-ג'ונג",
        "description": "${description} \n מדיטציה, יוגה, בודהיזם טיבטי, ליווי, אימון-אישי, לבהירות, איזון ושלום פנימי  לזוגיות טובה, מהנהיגות, העצמה, חינוך ותקשורת מקרבת",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": "City",
            "addressRegion": "Kfar Saba",
            "addressCountry": "IL"
        }
}`
                    this.update({currentPage})
                })()
            }
        }

        function pageName(page) {
            return {
                'for-whom': 'למי זה מיועד',
                'home': 'דף הבית',
                'about-me': 'אודותי',
                'articles': 'מאמרים וידע',
                'the-process': 'על התהליך'
            }[page] || page
        }

    </script>
</app>
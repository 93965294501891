<the-tools>
        <md-article article="about-the-tools">
    <script>
        import MdArticle from '../md-article.riot'
        export default {
            components: {
                MdArticle
            }
        }
    </script>

</the-tools>
export enum EventType {
    Practice = "תרגול",
    GroupMeeting = "מפגש קבוצתי",
    OpenPractice = "אימון פתוח",
    Teaching = "לימוד/העמקה",
    Announcement = "הודעה",
}

export enum EventStatus {
    Active = "פעיל",
    Paused = "מושהה",
}

export type AnnouncementType = 'regular' | 'important' | 'cancellation' | 'danger'

export enum CostType {
    Donation = "על בסיס נדיבות",
    None = "חינם",
    Fixed = "שקלים",
    Special = "ראו בפרטים",
}

export enum RecurrenceType {
    Weekly = "שבועי",
    BiWeekly = "דו-שבועי",
    Monthly = "חודשי",
    OneTime = "חד-פעמי",
}

export type RecurrenceData = string // e.g., "1-Sunday" for the first Sunday of the month

export class BusinessEvent {
    id = BusinessEvent.generateId()
    type = EventType.Practice
    description = ''
    title = ''
    subtitle = ''
    status = EventStatus.Active
    location = ''
    equipment: string | undefined
    costType = CostType.Donation
    basePrice = 50
    timeHour: number | undefined
    next: Date | undefined | null
    pictureUrl: string | undefined
    telegramLink: string | undefined
    whatsappLink: string | undefined
    otherUrl: string | undefined
    startDate: Date = new Date()
    endDate: Date | undefined
    recurrenceType: RecurrenceType | undefined
    extraRecurrenceData: RecurrenceData | undefined

    constructor(event: Partial<BusinessEvent> = {}) {
        if (event.timeHour)
            event.startDate?.setHours(Math.floor(event.timeHour), 60 * (event.timeHour - Math.floor(event.timeHour)))
        else {
            // @ts-ignore
            event.timeHour = event.startDate.getHours() + event.startDate.getMinutes() / 60
        }

        Object.assign(this, event)
    }

    get weekday() {
        return (this.next || this.startDate).toLocaleString('he', {weekday: 'long'})
    }


    get name(): string {
        return this.type + ': ' + this.title
    }

    get time() {
        // @ts-ignore
        const hh = Math.floor(this.timeHour)
        // @ts-ignore
        const mm = 60 * (this.timeHour - hh)
        return hh + ':' + mm
    }


    get costString(): string {
        switch (this.costType) {
            case CostType.Special:
                return 'ראה בפרטים'
            case CostType.Donation:
                return `
            על בסיס נדיבות
            תרומה מומלצת
            ${this.basePrice}
            `
            case CostType.None:
                return 'ללא עלות'
            case CostType.Fixed:
                return '' + this.basePrice
        }
    }

    getNextOccurrence(dateIterator: Date | null): Date | null {
        const now = new Date()
        if (this.endDate && dateIterator && dateIterator > this.endDate)
            return null

        if (!this.recurrenceType || this.recurrenceType === RecurrenceType.OneTime)
            return null

        let nextDate = new Date(dateIterator || this.next || this.startDate)

        let result: Date | null = null
        do {
            switch (this.recurrenceType) {
                case RecurrenceType.Weekly:
                    nextDate.setDate(nextDate.getDate() + 7)
                    break
                case RecurrenceType.BiWeekly:
                    nextDate.setDate(nextDate.getDate() + 14)
                    break
                case RecurrenceType.Monthly:
                    if (this.extraRecurrenceData) {
                        nextDate = this.adjustToMonthlyRecurrence(nextDate, this.extraRecurrenceData)
                    } else {
                        nextDate.setMonth(nextDate.getMonth() + 1)
                    }
                    break
                default:
                    return null
            }
            result = this.endDate && nextDate > this.endDate ? null : nextDate
            if (!result)
                return null
        } while (result < now)
        return result

    }

    private adjustToMonthlyRecurrence(date: Date, recurrenceData: RecurrenceData): Date {
        const [week, day] = recurrenceData.split('-')
        const weekNumber = parseInt(week)
        const dayOfWeek = this.getDayOfWeek(day)

        if (!isNaN(weekNumber) && (weekNumber < 1 || weekNumber > 5))
            throw new Error("Invalid week number in recurrence data")

        let newDate = new Date(date)
        newDate.setMonth((date.getMonth() + 1) % 12)

        if (day) {
            const diff = newDate.getDay() - dayOfWeek
            if (diff !== 0) {
                const newDayInMonth = newDate.getDay() + diff
                newDate.setDate(newDayInMonth)
            }
        }
        if (weekNumber) {
            const diff = getWeekOfMonth(newDate) - weekNumber
            if (diff !== 0) {
                const newDayInMonth = newDate.getDay() + diff * 7
                newDate.setDate(newDayInMonth)
            }
        }
        return newDate
    }

    private getDayOfWeek(day: string): number {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        return days.indexOf(day)
    }

    private static generateId(): string {
        return Math.random().toString(36).substring(2, 10)
    }
}

type EventOverride<T extends keyof BusinessEvent> = {
    [K in T]?: BusinessEvent[K]
} & {
    specialAnnouncement?: string
    announcementType?: string
    date?: Date
}

type Overrides = Record<string, EventOverride<keyof BusinessEvent>>

export class BusinessEventManager {
    private events: BusinessEvent[] = []
    private extraOverrides: Overrides = {}

    bulkAddEvents(eventsData: Partial<BusinessEvent>[]): void {
        this.events.push(...eventsData.map(event => new BusinessEvent(event)))
    }

    addEvent(eventData: Partial<BusinessEvent>): void {
        const newEvent = new BusinessEvent(eventData)
        this.validateEvent(newEvent)
        this.events.push(newEvent)
    }

    updateEvent(eventId: string, updatedData: Partial<BusinessEvent>): void {
        const index = this.events.findIndex(event => event.id === eventId)
        if (index !== -1) {
            const updatedEvent = new BusinessEvent({...this.events[index], ...updatedData})
            this.validateEvent(updatedEvent)
            this.events[index] = updatedEvent
        }
    }

    removeEvent(eventId: string): void {
        this.events = this.events.filter(event => event.id !== eventId)
    }

    setExtraOverrides(overrides: Record<string, EventOverride<keyof BusinessEvent>>): void {
        this.extraOverrides = overrides
    }

    updateExtraOverride(eventId: string, override: Partial<EventOverride<keyof BusinessEvent>>): void {
        this.extraOverrides[eventId] = {...this.extraOverrides[eventId], ...override}
    }

    /**
     *
     * @param instancesLimit maximum number of occurrences of a single event type in result
     */
    getNextEvents(instancesLimit: number = 1): BusinessEvent[] {
        const currentDate = new Date()
        const upcomingEvents: BusinessEvent[] = []

        for (const event of this.events) {
            let nextOccurrence = null
            nextOccurrence = event.getNextOccurrence(nextOccurrence)
            let instanceCounter = 0
            if (event.type === EventType.Announcement) {
                if (!event.endDate || event.endDate < currentDate)
                    event.next = event.startDate
                    upcomingEvents.push(this.applyOverrides(event))
            } else {
                let e = event
                while (nextOccurrence && instanceCounter++ < instancesLimit) {
                    e = new BusinessEvent({...e})
                    e.next = nextOccurrence
                    upcomingEvents.push(this.applyOverrides(e))
                    nextOccurrence = e.getNextOccurrence(nextOccurrence)
                    if (!nextOccurrence)
                        break
                }
            }
        }
        // @ts-ignore
        return upcomingEvents.sort((a, b) => a.next?.getTime() - b.next?.getTime())
    }

    private applyOverrides(event: BusinessEvent): BusinessEvent {
        let override = this.extraOverrides[event.id]
        if (!override)
            return event
        // @ts-ignore
        if (override.date && !areDatesEqual(override.date, event.next))
            return event
        return new BusinessEvent({...event, ...override})
    }

    private validateEvent(event: BusinessEvent): void {
        if (!event.startDate || isNaN(event.startDate.getTime())) {
            throw new Error("Invalid start date")
        }
        if (!Object.values(EventType).includes(event.type)) {
            throw new Error("Invalid event type")
        }
        // Add more validation as needed
    }
}

export function getWeekOfMonth(date: Date) {
    // Get the first day of the month
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    // Get the day of the week for the first day of the month (0 - Sunday, 1 - Monday, ...)
    const firstDayOfWeek = firstDayOfMonth.getDay();

    // Calculate the day of the month (1-based)
    const dayOfMonth = date.getDate();

    // Adjust to calculate the first week starting from Monday (or Sunday based on your locale)
    // Add the number of days passed since the first day of the month
    const adjustedDayOfMonth = dayOfMonth + firstDayOfWeek;

    // Calculate the week number by dividing by 7 (days per week)
    return Math.ceil(adjustedDayOfMonth / 7);
}

/** compare two dates without time
 * */
export function areDatesEqual(d1: Date, d2: Date): boolean {
    return d1.toDateString() === d2.toDateString()
}


// @ts-ignore
export const eventManager = new BusinessEventManager();

(async () => {
    init()
})()

//
// async function readEventsFromFile(): Promise<void> {
//     const events = await fetch('/events.json').then(raw => raw.json());
//     const eventsOverride = await fetch('/events-overrides.json').then(raw => raw.json());
//     eventManager.bulkAddEvents(events)
//     eventManager.setExtraOverrides(eventsOverride)
// }
//


function init() {

// Test Code
    const initialEvents: Partial<BusinessEvent>[] = [
        {
            id: 'lojong-circles',
            type: EventType.GroupMeeting,
            title: "מעגלי לו-ג'ונג",
            subtitle: 'מעגלי שיחה ורוח',
            startDate: new Date('2024-09-1'),
            timeHour: 16.5,
            description: `         
מה יהיה במפגשים:
לימוד רוחני קצר: נושאים מגוונים בתחום המדיטציה, מיינדפולנס ותרגולי לו-ג'ונג.
תשובות לשאלות: הזדמנות לשאול שאלות ולקבל תשובות ממוקדות.
תרגול קבוצתי: מדיטציות ותרגולים מעשיים שיתמכו בכם בחיי היומיום.

מיועד *גם* למתרגלי דהרמה וותיקים

יש צורך בהרשמה מראש; צרו קשר להרשמה
            `,
            recurrenceType: RecurrenceType.Weekly,
            costType: CostType.Donation,
            basePrice: 75,
            whatsappLink: 'https://chat.whatsapp.com/HTkxnhU1eWqInFBt300zzg'
        },
        {
            id: 'park-yoga',
            title: "יוגה וצ'י-קונג בפארק",
            subtitle: 'אימון/שיעור פתוח',
            startDate: new Date('2024-09-10T18:30:00'),
            description: `
            שילוב המתאים לכל אדם, ומועיל לכל אדם, של קצת צ'י-קונג עם יוגה (ויניאסה)
זהו תרגול ולימוד תחת כיפת השמיים והזדמנות נפלאה להתחבר לגוף ולנפש, להירגע ולהתחדש.שיחת דהרמה/לו-ג'ונג בסוף, למי שרוצה.
מתאים גם לילדים מגיל 7!

            `,
            recurrenceType: RecurrenceType.Weekly,
            // extraRecurrenceData: "1-Sunday",
            type: EventType.Practice,
            costType: CostType.Donation,
            equipment: 'מזרון יוגה, מים, אולי משהו נגד יתושות',
            basePrice: 50,
            whatsappLink: 'https://chat.whatsapp.com/H3KY0v6mAoiFf0ayfsVZf3'
        },
        {
            id: 'book1',
            type: EventType.Announcement,
            title: 'הספר The Lotus and the Sword ראה אור!',
            startDate: new Date('2024-09-18T18:30:00'),
            description: `
            ניתן לראות ולהציץ ואף לקנות באמזון, בלינק המצורף
            `,
            costType: CostType.Special,
            otherUrl: 'https://www.amazon.com/dp/B0D3WC5CJN/ref=sr_1_1?crid=ZXAF5FXDT6O2&dib=eyJ2IjoiMSJ9.RBsfF21M-MG_OGwywHsm_-IfCajv4JhY7KDjZLx5xpdPDrJ-Tt7ojKrS-Qvg36B7BgRHWbdR-xaePJTtvh_1fRdlXd8q0p6evXp06LfmHspiCjyjxH52cXZbNtZ7D6lju8K9UgiSalVjobGS8wmKFlMFhyVYULI3W3OjfCixVtfcfPptgSzTN0gyhW1HoWm7vTBWiSlR4WzTg4v8kzuFixOoBPOMW8hBFLXTXoBHgtA.-nJBKXBPgsnPkWUl1J_sgoKBX2BceelWrPHQuJAdI5A&dib_tag=se&keywords=the+lotus+and+the+sword&qid=1725068818&s=books&sprefix=the+lotus+and+the+sword%2Cstripbooks-intl-ship%2C189&sr=1-1'
        },

    ]

    eventManager.bulkAddEvents(initialEvents)

    // @ts-ignore
    eventManager.updateExtraOverride('park-yoga', {
        announcementType: 'important',
        specialAnnouncement: "שינוי יום חד פעמי! שימו לב שהפעם זה ביום ה'",
        date: new Date(2024, 8, 17),
        next: new Date('2024-09-19 18:30')
    })
    // eventManager.updateExtraOverride('workshop-1', {
    //     announcementType: 'danger',
    //     specialAnnouncement: 'מסוכן',
    //     date: new Date(2024, 9, 14)
    //
    // })

    console.log("After Applying an Override:")
    console.log(eventManager.getNextEvents(5))
}




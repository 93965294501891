<contact-widget>
    <div class="contact-info">
        מלאו את הפרטים וניצור אתכם קשר
        <div class="name">
            <label>
                שם מלא
                <input ref="name" placeholder="שם ושם משפחה" onblur={processForm} required>
            </label>
        </div>
        <br>
        <div class="phone">
            <label>
                מספר טלפון
                <input type="tel" pattern="0[1-9][0-9]-?[0-9]\{7}" ref="phone" placeholder="טלפון" onblur={processForm}
                       required>
            </label>
        </div>
        <br>
        <button onclick={sendPhone}>שלח</button>
    </div>
    <div> או, התקשרו אלינו בעצמכם:&nbsp;</div>
    <a href="tel:+972-54-4799404" target="_blank" class="button">
        <span class="phone-icon">&#128222;</span> &nbsp;
        <span class="phone-number">054-4799404</span>
    </a>

    <style>

        :host {
            background-color: var(--special-color2);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1em;
            padding: 1em;
            border-radius: 0.25em;
            @media (min-width: 1000px) {
                flex-direction: row;
            }
        }

        input {
            line-height: 1.5rem;
            font-size: 1.5rem;
            border-radius: 0.25em;
            text-align: center;
        }

        div {
            font-weight: bold;
        }

        div > div {
            font-weight: normal;

        }

        button {
            width: 100%;
            font-weight: bold;
            font-size: 1em;

        }

        .contact-info {
            max-width: 100%;
            width: 100%;
            font-weight: bold;
        }


    </style>
    <script>

        import {stateService} from "../services/state-service";
        import {collectValues} from "field-assist";
        import {writeFormData} from "../services/firebase";

        export default {

            openContactForm(value = true) {
                location.href = '/contact'
            },

            processForm() {
                const node = this.$('.contact-info')
                return collectValues(node)
            },

            async sendPhone() {

                const values = this.processForm()
                if (values._errors)
                    return

                await writeFormData(values)

                sessionStorage.formPhoneNumber = values.phone

                window.location.href = `/thanks`
            }

        }
    </script>
</contact-widget>
/* Riot v9.4.0, @license MIT */
import $ from '../dependencies/bianco.query/index.next.js';

const COMPONENT_DOM_SELECTORS = Object.freeze({
  // component helpers
  $(selector) {
    return $(selector, this.root)[0]
  },
  $$(selector) {
    return $(selector, this.root)
  },
});

export { COMPONENT_DOM_SELECTORS };

<article-display>
    <div class="back">
        <a if={state.selectedArticle?.meta.entryType === 'article'} href="/articles"> לספריה</a>
        <a if={state.selectedArticle?.meta.entryType === 'blogPost'} href="/blog">לכל הבלוג</a>

        <div class="read-time" dir="ltr"> זמן קריאה {Math.round(state.selectedArticle?.meta.wordCount / 160)} דקות</div>
        <a if={state.parentArticle}
           href={"/lib/"+state.parentArticle.meta.name}>{state.parentArticle.meta.title}</a>
    </div>
    <div class="article-container">
        <h2 if={state.selectedArticle?.meta.entryType === 'blogPost'} class="time" dir="ltr">{ state.fileTime}
            עודכן/פורסם ב </h2>
        <md-article if={state.articleReady} article={state.selectedArticle.meta.name}></md-article>
        <p if={state.selectedArticle?.meta.entryType !== 'blogPost'} class="time" dir="ltr">{state.fileTime} עודכן/פורסם
            ב</p>
    </div>
    <p></p>
    <div class='not-ready' if={!state.articleReady}>
        כתיבתו של ערך/מאמר זה עדיין לא הושלמה
    </div>

    <!--    <div class="fb-comments" data-href="https://www.lojong-studio.com/*" data-width="50%" data-numposts="10"></div>-->

    <style>
        .back {
            display: flex;
            justify-content: space-between;
            position: relative;
            bottom: -1.5em;
            padding: 0 0.5em;
            grid-row: 1;
            background-color: rgba(218, 222, 169, 0.8);
        }

        .back a:hover {
            font-weight: bold;
        }

        .back .read-time {
            padding: 0 1em;
        }

        .article-container {
            grid-row: 3;
        }

        .not-ready {
            background: var(--opaque-background-color);
            padding: 1em;
            font-size: 1.75em;
            color: var(--accent-color);
            border-radius: 0.5em;

        }


    </style>
    <script>

        import MdArticle from '../md-article.riot'
        import {stateService, dispatcher} from "../../services/state-service"
        import {articleService} from "../../services/article-service";

        export default {
            components: {
                MdArticle
            },
            listener: null,
            async setArticle(articleName) {
                const article = await articleService.getArticle(articleName)
                const parentArticle = article.meta.parent && await articleService.getArticle(article.meta.parent)
                this.update({
                    fileTime: new Date(article.meta.fileTime).toLocaleDateString(),
                    selectedArticle: article,
                    parentArticle,
                    articleReady: article.meta.finished !== 'no'
                })
            },
            onMounted() {
                this.listener = dispatcher.on('state:changed', event => {
                    if (event.data.name !== 'article')
                        return
                    this.setArticle(stateService.context);
                })

                this.setArticle(this.props.article || stateService.context)
            },
            onUnmounted() {
                dispatcher.off(this.listener)
            }

        }

    </script>
</article-display>
/* Riot v9.4.0, @license MIT */
import { createCoreAPIMethods } from './create-core-api-methods.js';
import { createExpression as create$4 } from '../dependencies/@riotjs/dom-bindings/dist/dom-bindings.js';

/**
 * Create the bindings to update the component attributes
 * @param   {HTMLElement} node - node where we will bind the expressions
 * @param   {Array} attributes - list of attribute bindings
 * @returns {TemplateChunk} - template bindings object
 */
function createAttributeBindings(node, attributes = []) {
  const expressions = attributes.map((a) => create$4(node, a));
  const binding = {};

  return Object.assign(binding, {
    expressions,
    ...createCoreAPIMethods((method) => (scope) => {
      expressions.forEach((e) => e[method](scope));

      return binding
    }),
  })
}

export { createAttributeBindings };

<welcome-org>
    <h2 class="english">
        <a href="/lib/english-sessions" target="_blank">English speakers friendly! (click me)</a>
    </h2>
    <article>
        <div class="welcome-top">
            <h1>ברוכים הבאים ללו-ג'ונג לארגונים וקבוצות - סדנאות לאימון תודעה, איזון והתפתחות אישית ומקצועית בגישה בודהיסטית, יוגית
                וטנטרית</h1>
            <h3 class="sub-header">
                שלווה, בהירות ואיזון בקריירה מאתגרת ומורכבת והפיכת קשיים להזדמנויות
                <br>
            </h3>
            <p class="slogan2">
                בלו-ג'ונג, אנו מביאים חכמה עתיקה אל תוך העולם המודרני: שילוב של טכניקות ושיטות מהמזרח וטכניקות ניהול
                מודרניות, כדי ליצור איזון, שלווה והגשמה אישית ומקצועית.
                <br>
                אנו עוסקים בפיתוח אישי המדגיש פיתוח בהירות פנימית קורנת וחדה, ומיומנויות לחיים - פנימיות וחיצוניות - של
                תודעה מאומנת, מאוזנת ורגועה
                בכלים בודהיסטים, יוגיים, טאוטיסטים ועוד.
            </p>
        </div>
        <img class="my-pic" src={MyPic} alt="my-pic">

        <div class="super-points">
            <h1>מדוע ולמה?...</h1>
            <ul class="checked-list">
                <li>
                    כי מעבר לניסיון ולאינטליגנציה - הצלילות והבהירות חשובים אף יותר
                </li>

                <li>
                    כי תקשורת-מקרבת עם
                    <a href="/lib/Listening-and-communication">בסיס מדיטטיבי רב עצמה</a>
                    שמעצים את
                    <a href="/lib/Listening-and-communication">הקשב</a>
                    והבהירות והופך אינטראקציות ליעילות ונעימות יותר.
                </li>
                <li>
                    כי ניהול ומנהיגות המועצמים על ידי הבהירות הצלולה והשלווה של הדהרמה
                    <a href="/lib/Hatha-yoga">והיוגה</a>
                    הם אפקטיביים ונעימים יותר.
                </li>

                <li>
                    כי התגברות על בעיות רגשיות,
                    <a href="/lib/habits">התמכרויות, הרגלים רעים</a>
                    ולמעשה הפיכתם לכלי לצמיחה והעצמה עם חכמת
                    <a href="/lib/listening">הטנטרה</a>

                    ו<a href="/lib/depth-psychology">פסיכולוגית עומק בודהיסטית</a>
                    מעלים את יעילותינו ואת הסיפוק מחיינו ובכלל.
                </li>
                <li>
                    כי הבחינה והשיפור המתמידים של שיטות, גישות, תכניות וצורות מחשבה הם הבסיס לחדשנות בריאה, איכות ויעילות.
                </li>
            </ul>
        </div>

        <div class="who-are-we">
            <h2>מי אנחנו</h2>
            <p>
                <a href="/about-me">
                    אבי תשובה
                </a>
                , מייסד לו-ג'ונג, מביא עימו ניסיון רב, של עשרות שנים, בתחומי המדיטציה, היוגה והטאואיזם, וגם עשרות שנים בעולם
הייטק, היזמות והניהול
                 בכל ספקטרום התפקידים, ומציע שיטות מעשיות ומותאמות לצרכים הייחודיים של החיים המודרניים והארגון
                המודרני.

            </p>
            <p>
                אגב, פירוש השם
                <b>לו-ג'ונג</b>
                הוא "אימון-התודעה", והוא מתייחס ללימוד מאוד ספציפי בבודהיזם הטיבטי. ולא - לא נלמד את
                הלימוד המסוים הזה במסגרות הרגילות שאנו מציעים, אך ניקח גם משם כלים ועקרונות שימושיים.
            </p>
        </div>

        <div class="programs">
            <h2>הצטרפו אלינו לסדנאות ותוכניות מיוחדות</h2>
            <ul>
                <li>סדנאות  לארגונים לשיפור ההרמוניה, היעילות, איכות העבודה ובריאות העובדים</li>
                <li>תפירה של תוכניות שמותאמות במיוחד לצרכים של קבוצות וארגונים, כולל חקירה משותפת של הצרכים</li>
                <li>אפשרות לליווי מתמשך לאחר הסדנאות</li>
                <li> סדרות מפגשים אישיים  לפיתוח אישי ומקצועי למאמנים, מתרגלים, אנשי חינוך</li>
                <li> ליווי, אימון והדרכה של <a href="/lib/relationships1" target="_blank">זוגות, משפחות</a> <a
                        href="/lib/youth" target="_blank"> ונוער</a></li>
                <li style="color:var(--special-color)">השבחת מורים, מטפלים, מאמנים ומדריכים</li>
            </ul>
        </div>
        <br>
        <plans filter="organizations"/>
        <recommendations/>
        <contact-widget/>

        <div class="read-more-stuff">
            <h2>
                קראו עוד:
            </h2>
            <ul class="checked-list">
                <li><a href="/articles"> ספרית המאמרים והידע</a></li>
                <li><a href="/lib/about-the-tools"> על הכלים שאנו משתמשים בהם</a></li>
                <li><a href="/for-whom">למי זה מיועד</a></li>
                <li><a href="/about-me">אודותי</a></li>
            </ul>

        </div>
        <contact-widget/>
    </article>

    <contact-form if={state.contactFormOpen} closeCB={()=>openContactForm(false)}/>

    <style>
        the-process article {
            max-height: fit-content;
        }

        .article-carousel {
            display: flex;
            flex-direction: column;
            align-items: center;

        }

        .article-carousel h2 {
            align-self: start;
        }

        recent-articles {
            max-width: 60em;
        }

        .english {
            direction: ltr;
            font-size: 1rem;

            transform: rotate(10deg);
            text-align: center;
        }

        .english a {
            font-weight: bold;
            background-color: var(--opaque-background-color);
            border: 4px double var(--accent-color);
            padding: 3px;
            border-radius: 1em;
        }

    </style>
    <script>

        import ArticleDisplay from './article-display.riot'
        import TheProcess from './the-process.riot'
        import ContactWidget from '../contact-widget.riot'
        import Recommendations from '../recommendations.riot'
        import RecentArticles from '../recent-articles.riot'
        import Plans from './plans.riot'
        import MyPic from '../../styles/resources/avi-a.jpg'

        export default {
            components: {
                ArticleDisplay,
                TheProcess,
                ContactWidget,
                Recommendations,
                RecentArticles,
                Plans
            },
            MyPic
        }
    </script>

</welcome-org>